import React from "react"
// import mixpanel from 'mixpanel-browser'
//import { withMixpanel } from 'gatsby-plugin-mixpanel'
import { useMixpanel } from 'gatsby-plugin-mixpanel'

/*
if (typeof document !== 'undefined') {
  // Code referencing the "document" object
  mixpanel.track_links("a.showreel-item-button", "showreel:item:clicked", {
    "referrer": document.referrer
  });
}
*/

const ShowreelImage = props =>
  <div class="showreel-image" data-no-shadow={props.noShadow} data-image-type={props.type} style={{
      marginTop: props.marginTop ? props.marginTop : null
    }}>
    <img src={props.src} />
  </div>

const ShowReelItemGallery = props =>
  <div class="showreel-gallery"
    data-gallery-type={props.type}
    data-maximized={props.isMaximized}
    onClick={props.onClick}
    data-aos={props.index % 2 ? 'fade-right' : 'fade-left'}>
    {props.galleryImages.map((galleryImage, index) =>
      <ShowreelImage key={index} type={galleryImage.type} src={galleryImage.src} marginTop={galleryImage.marginTop} noShadow={galleryImage.noShadow} />
    )}
  </div>

const ShowReelItemAction = props => {
  const mixpanel = useMixpanel();
    /*
    mixpanel.track_links("a.showreel-item-button", "showreel:item:clicked", {
      "referrer": document.referrer
    });
    */
    return <a class="showreel-item-button" data-type={props.type} href={props.url} target="_blank">{props.children}</a>;
}


const ShowReelItemInfo = props =>
  <div class="showreel-info" data-aos={props.index % 2 ? 'fade-left' : 'fade-right'}>
    <h3 class="showreel-item-category">{props.category} • {props.date}</h3>
    <h3 class="showreel-item-title">{props.title}</h3>
    <div class="showreel-item-description">{props.description}</div>
    <div class="showreel-item-buttons">
      { props.actions
        ? props.actions.map(action => <ShowReelItemAction key={action.label} type={action.type} url={action.url}>{action.label}</ShowReelItemAction>)
        : props.url ? <ShowReelItemAction url={props.url}>Learn more</ShowReelItemAction> : null
      }
    </div>
  </div>

class ShowReelItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMaximized: false
    };

    this.ref = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    //const { mixpanel } = this.props;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = event => {
    const scrollTop = event.srcElement.documentElement.scrollTop;

    if (this.ref.current.getBoundingClientRect().top < 600 &&
      this.ref.current.getBoundingClientRect().top > 600 - this.ref.current.clientHeight) {
      if (typeof this.props.onScrollIntoViewport === 'function')
        this.props.onScrollIntoViewport(this.props.itemId);
    }
  }

  toggleMaximize = () => {
    this.setState({ isMaximized: !this.state.isMaximized });
  }

  render() {
    return (
      <div class="showreel-item" ref={this.ref} data-item-id={this.props.itemId} data-active={this.props.isActive}>
        <ShowReelItemInfo
          index={this.props.index}
          category={this.props.category}
          date={this.props.date}
          title={this.props.title}
          description={this.props.description}
          url={this.props.url}
          actions={this.props.actions} />
        <ShowReelItemGallery
          index={this.props.index}
          type={this.props.galleryType}
          galleryImages={this.props.galleryImages}
          isMaximized={this.state.isMaximized}
          onClick={this.toggleMaximize} />
      </div>
    )
  }
}

const ScrollIndicator = props =>
  <div class="showreel-scroll-indicator" data-visible={props.isVisible} data-hoverable={props.isHoverable}>
    {props.items.map(item =>
      <div class="scroll-indicator-item"
        key={item.id}
        data-item-id={item.id}
        data-item-name={item.title}
        onClick={() => props.onItemClicked(item.id)}
        data-active={props.activeItemId === item.id}></div>
    )}
  </div>

class ShowReel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowreelInViewport: false,
      isScrollIndicatorVisible: false,
      activeItem: null
    };

    this.ref = React.createRef();

    this.itemRefs = this.props.items.reduce((refs, item) => {
      refs[item.id] = React.createRef();
      return refs;
    }, {});
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    this.scrollToComponent = require('react-scroll-to-component-ssr');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = event => {
    const scrollTop = event.srcElement.documentElement.scrollTop;

    const isShowreelInViewport = this.ref.current.getBoundingClientRect().top < 600 &&
      this.ref.current.getBoundingClientRect().top > 600 - this.ref.current.clientHeight;

    this.setState({
      isShowreelInViewport: isShowreelInViewport,
      isScrollIndicatorVisible: isShowreelInViewport
    });

    if (this.hideScrollIndicatorTimeout)
      clearTimeout(this.hideScrollIndicatorTimeout);

    this.hideScrollIndicatorTimeout = setTimeout(() => {
      this.setState({
        isScrollIndicatorVisible: false
      });
    }, 2000);
  }

  goToItem = item => {
    if (!this.itemRefs[item]) return;
    if (this.state.activeItem === item) return;

    this.scrollToComponent(this.itemRefs[item].current, {
      offset: -200,
      align: 'top',
      duration: 1000
    });
    this.setState({ activeItem: item });
  }

  handleItemScrollIntoViewport = item => {
    if (!this.itemRefs[item]) return;
    if (this.state.activeItem === item) return;

    this.setState({ activeItem: item });
  }

  render() {
    return (
      <div class="showreel" ref={this.ref}>
        {this.props.items.map((item, index) =>
          <ShowReelItem
            key={item.id}
            itemId={item.id}
            ref={this.itemRefs[item.id]}
            onScrollIntoViewport={this.handleItemScrollIntoViewport}
            index={index}
            isActive={this.state.activeItem === item.id}
            category={item.category}
            date={item.date}
            title={item.title}
            description={item.description}
            url={item.url}
            actions={item.actions}
            galleryType={item.gallery.type}
            galleryImages={item.gallery.images} />
        )}
        {this.props.showScrollIndicator &&
          <ScrollIndicator
            items={this.props.items}
            activeItemId={this.state.activeItem}
            isVisible={this.state.isScrollIndicatorVisible}
            isHoverable={this.state.isShowreelInViewport}
            onItemClicked={this.goToItem} />}
      </div>
    )
  }
}

export default ShowReel
