import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Footer from "./footer"
import FrontPageHeader from "./frontPageHeader"

class FrontPageLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      backgroundBrightness: 1
    };
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = event => {
    const scrollTop = event.srcElement.documentElement.scrollTop;
    this.setState({
      backgroundBrightness: Math.min(1, Math.max(0, 1 - scrollTop / 4 / window.innerHeight))
    });
  }

  render() {
    const { children } = this.props
    //const rootPath = `${__PATH_PREFIX__}/`
    //const blogPath = `${__PATH_PREFIX__}/blog/`

    return (
      <div class="page-wrapper">
    	  <div id="page-background" class="page-background" style={{
            filter: `brightness(${this.state.backgroundBrightness})`
          }}>
    			<div class="cursor-follower"></div>
    		</div>
    		<div class="page-content">
          <FrontPageHeader
            onNavItemClicked={this.props.onNavItemClicked}
            onCTAClicked={this.props.onCTAClicked}
            activeSection={this.props.activeSection} />
          <main>{children}</main>
        </div>
        <Footer enableAOS={this.props.enableAOS} />
      </div>
    )
  }
}

export default FrontPageLayout
