import React from "react"

import Section from "../section"
import ShowReel from "./showReel"

// TODO get data from graphql

// TODO rename asset folders to camelCase
// TODO images from graphql, too
import formsHTML from "../../../content/assets/show-reel/forms-html-component.png"
import modelerAPISwagger from "../../../content/assets/show-reel/modeler-api.png"
import modelerAICanvas from "../../../content/assets/show-reel/ai-canvas.png"
import fikaTalkPhone from "../../../content/assets/show-reel/fikatalk-phone-2.png"
import fikaTalkPhoneChannels from "../../../content/assets/show-reel/fikatalk-phone-channels.png"
import fikaTalkPhoneExplore from "../../../content/assets/show-reel/fikatalk-explore.png"
import fikaTalkPhoneCalendar from "../../../content/assets/show-reel/fikatalk-calendar.png"
import cardLayout from "../../../content/assets/show-reel/card-layout.png"
import cardLayoutDocument from "../../../content/assets/show-reel/card-layout-document.png"
import cardLayoutEditor from "../../../content/assets/show-reel/card-layout-editor.png"
import surveyDesktop from "../../../content/assets/show-reel/survey.png"
import surveyMobile from "../../../content/assets/show-reel/MobileSurveys_wAspectRatio.png"
import infoboardDesktop from "../../../content/assets/show-reel/InfoboardDesktop.png"
import travelAssistantDesktop from "../../../content/assets/show-reel/travel_assistant.png"
import ticketBotDesktop from "../../../content/assets/show-reel/vodafone_ticketing.png"
import eiHomeMobile1 from "../../../content/assets/show-reel/easyinstall_home/AppScreen4.png"
import eiHomeMobile2 from "../../../content/assets/show-reel/easyinstall_home/AppScreen5.png"
import eiHomeMobile3 from "../../../content/assets/show-reel/easyinstall_home/AppScreen3.png"
import eiHomeMobile4 from "../../../content/assets/show-reel/easyinstall_home/AppScreen1.png"
import chatbotDesktop from "../../../content/assets/show-reel/Chatbot.png"
import chatbotMobile from "../../../content/assets/show-reel/MobileChatbot_wAspectRatio.png"
import tileBuilder from "../../../content/assets/show-reel/tilebuilder.png"
import pivotInfoboard from "../../../content/assets/show-reel/Pivot_BI.png"
import infoboard from "../../../content/assets/show-reel/Infoboard.png"

/*
TODO Web Modeler CI/CD integration
case study
- customers asked frequently for a VCS sync
- VCS sync is expensive to build, especially in the early stage
- We digged deeper to uncover the actual need
- We found that customers actually need a tight integration into their SDLC
- As a solution, we built a file and version mgmt API on top of Web Modeler, and written a CI/CD integration guide that brings all existing fragmented pieces together
- As a result, we unlocked Web Modeler for development teams of any size and successfully replaced the previous local modeler
- In addition, the demand we found is so huge that we founded a dedicated team that focuses on all the Process Development Lifecycle 
*/

class ShowReelSection extends React.Component {
  render() {
    return (
      <Section name="showReel" title="Show reel" background="light" enableAOS={false} showScrollIndicator={true} onScrollIntoViewport={this.props.onScrollIntoViewport}>
        <ShowReel showScrollIndicator={true} items={[
            {
              id: "form-js",
              category: "Product, UX, Low-Code Toolkit, & DevX",
              date: "2024",
              title: "form-js",
              description: "Developer-friendly & extensible JSON-based form editor and renderer that can be embedded anywhere",
              actions: [
                { label: 'Show on GitHub', type: 'github', url: 'https://github.com/bpmn-io/form-js' },
                { label: 'Examples', type: 'github', url: 'https://github.com/camunda-community-hub/camunda-forms-examples' },
                { label: 'Try it', url: 'https://demo.bpmn.io/form' }
              ],
              gallery: {
                images: [
                  { src: formsHTML }
                ]
              }
            },
            {
              id: "web-modeler-ci-cd",
              category: "Product, APIs, Developer Experience",
              date: "2023",
              title: "Web Modeler CI/CD Integration",
              description: "File and version management API, SDLC best practices, test tooling, and CI/CD process blueprint for a tight integration of Camunda in the SDLC",
              actions: [
                { label: 'Read the guide', url: 'https://docs.camunda.io/docs/guides/devops-lifecycle/integrate-web-modeler-in-ci-cd/' },
                { label: 'Explore process blueprint', url: 'https://marketplace.camunda.com/en-US/apps/439170/cicd-pipeline' }
              ],
              gallery: {
                images: [                  
                  { src: modelerAPISwagger }
                ]
              }
            },
            {
              id: "web-modeler",
              category: "Product",
              date: "2022",
              title: "Web Modeler",
              description: "BPMN process modeling + testing platform for enhanced business-IT collaboration",
              actions: [
                { label: 'Learn more', url: 'https://camunda.com/de/platform/modeler/' },
              ],
              gallery: {
                images: [                  
                  { src: modelerAICanvas }
                ]
              }
            },
            {
              id: "cardLayout",
              category: "Enterprise Application UX & Developer Experience",
              date: "2021",
              title: "CURSOR-CRM \nCard Layout",
              description: "New, composable card layout for CURSOR-CRM web application to empower customers to build their own CRM with ease",
              url: "https://www.cursor.de/software/cursor-crm",
              gallery: {
                type: "multi-desktop-screens",
                images: [                  
                  { type: "app", src: cardLayoutEditor, marginTop: "12px", noShadow: true },
                  { src: cardLayout },
                  // { src: cardLayoutEditor }
                ]
              }
            },
            {
              id: "fikaTalk",
              category: "Idea, Product, UX, & Backend/API Engineering",
              date: "2021",
              title: "FikaTalk",
              description: '"Coffee break" video chat app for remote teams to break silos, accelerate knowledge transfers and support diversity',
              gallery: {
                type: "multi-app-screens",
                images: [                  
                  { type: "app", src: fikaTalkPhoneChannels, marginTop: "24px" },
                  { type: "app", src: fikaTalkPhoneExplore, marginTop: "16px" },
                  { type: "app", src: fikaTalkPhoneCalendar, marginTop: "8px" },
                  { type: "app", src: fikaTalkPhone }
                ]
              }
            },
            {
              id: "surveys",
              category: "Full Stack Development & Product Shipped",
              date: "2020",
              title: "CURSOR Surveys",
              description: "Scalable survey tool for online surveys and callcenters with native CRM integration",
              gallery: {
                type: "desktop-and-app-screen",
                images: [
                  { type: "desktop", src: surveyDesktop },
                  { type: "app", src: surveyMobile }
                ]
              }
            },
            {
              id: "travelAssistant",
              category: "Hackathon",
              date: "2019",
              title: "Oberösterreich \nTravel Assistant",
              description: "AI travel assistant to help travellers plan trips and activities around a specific route with smart suggestions",
              url: "https://www.uppercode.at/",
              gallery: {
                images: [{ src: travelAssistantDesktop }]
              }
            },
            {
              id: "ticketBot",
              category: <span>Hackathon • <span class='first-place-ribbon'></span> 1st place winner</span>,
              date: "2019",
              title: "Vodafone Ticket Bot",
              description: "24/7 self service bot and transparent incident management for B2B customer service",
              url: "https://www.giessener-anzeiger.de/wirtschaft/wirtschaft-regional/giessener-heavy-metal-hackers-gewinnen-hackathon_20199444",
              gallery: {
                images: [{ src: ticketBotDesktop }]
              }
            },
            {
              id: "eiHome",
              category: "Full Stack Development & Product",
              date: "2017",
              title: "EasyInstall",
              description: "Dead simple property maintenance software for businesses and property owners",
              gallery: {
                type: "multi-app-screens",
                images: [
                  { src: eiHomeMobile1, marginTop: "24px" },
                  { src: eiHomeMobile2, marginTop: "16px" },
                  { src: eiHomeMobile3, marginTop: "8px" },
                  { src: eiHomeMobile4 }
                ]
              }
            },
            {
              id: "customerServiceBot",
              category: "Chatbot",
              date: "2018",
              title: "CURSOR-CRM \nService Chatbot",
              description: "Easily embeddable chatbot for customer self-service solutions with native CRM integration",
              url: "https://www.cursor.de/unternehmen/aktuelles/64-veranstaltungen/8136-kuenstliche-intelligenz-innovationstag-begeistert-crm-anwender",
              gallery: {
                type: "desktop-and-app-screen",
                images: [
                  { type: "desktop", src: chatbotDesktop },
                  { type: "app", src: chatbotMobile }
                ]
              }
            },
            {
              id: "tilebuilder",
              category: "Full Stack Development & Product",
              date: "2014",
              title: "CURSOR-CRM \nInfoboard Tilebuilder",
              description: "Web IDE for flexible development of custom tiles (interactive dashboard components) entirely in JS for CURSOR-CRM Infoboards",
              gallery: {
                images: [{ src: tileBuilder }]
              }
            },
            {
              id: "infoboard",
              category: "Full Stack Development & Product",
              date: "2014",
              title: "CURSOR-CRM Infoboard",
              description: "Fully extensible and highly individualizable dashboard technology for CRM, BI, and case management",
              url: "https://www.cursor.de/unternehmen/aktuelles/63-allgemein/5917-my-crm-neu-definiert-maximale-individualitaet-dank-infoboard-technologie",
              gallery: {
                type: "multi-desktop-screens",
                images: [
                  { src: pivotInfoboard, marginTop: "12px" },
                  { src: infoboard }
                ]
              }
            }
          ]} />
      </Section>
    )
  }
}

export default ShowReelSection
