import React from "react"

import Section from "../section"

// TODO get data from graphql

// TODO get per graphql
import camundaLogo from "../../../content/assets/work-company-logos/camunda.png"
import cursorLogo from "../../../content/assets/work-company-logos/cursor.jpg"
import aviateLogo from "../../../content/assets/work-company-logos/aviate.png"

const HistoryItem = props =>
  <div class="history-item">
    <div class="history-icon" data-aos="zoom-in" style={{
        backgroundImage: `url(${props.logo})`,
        backgroundColor: props.logoBackgroundColor ? props.logoBackgroundColor : '#2f47a6'
      }}></div>
    <div class="history-data" data-aos="fade-right">
      <h3 class="history-title">{props.title}</h3>
      <div class="history-subtitle">{props.subtitle}</div>
      <div class="history-description">{props.description}</div>
      <div class="history-date-range">{props.dateRange}</div>
    </div>
  </div>

class WorkHistorySection extends React.Component {
  render() {
    return (
      <Section name="workHistory" title="Work history" background="dark" showScrollIndicator={true} onScrollIntoViewport={this.props.onScrollIntoViewport}>
        <div class="history-wrapper">
        <HistoryItem
            title="Camunda"
            subtitle="Product Manager"
            description="Camunda provides the most developer-friendly process orchestration platform that enables true end-to-end orchestration. I led Camunda's developer-focused BPMN modeling portfolio, guiding strategy and growth. I drove the Web Modeler's success, expanding user base and enterprise clients. We helped professional developers to speed up their software development lifecycle by integrating seamlessly into developer workflows. I also introduced a broad range of developer tools, APIs, SDKs, and an off-the-shelf Tasklist app, tailored for human task management, gaining traction with early adopters."
            dateRange="2022 - 2024"
            logo={camundaLogo}
            logoBackgroundColor="black" />
          <HistoryItem
            title="CURSOR Software AG"
            subtitle="Product Manager"
            description="CURSOR is the specialist in CRM and BPM for utilities in Germany and beyond. At CURSOR, I transitioned from software engineering to product management, driving innovation and addressing customer needs. I led initiatives such as transforming legacy systems into cloud-based solutions and revolutionizing help desk operations with case management, efficient human workflows, chatbots, and surveys. Spearheading our first cloud-native service and implementing cost-saving low-code UI solutions for developers and business were among my notable contributions."
            dateRange="2011 - 2022"
            logo={cursorLogo} />
          <HistoryItem
            title="Aviate Solutions"
            subtitle="Founder"
            description="We founded the company in 2015 with the vision of creating a platform to digitize professional maintenance and field service management. Working solo or with small teams, I developed a SaaS solution with Rails and a native Android app. Despite launching to the B2B market within a year and acquiring 20 customers, profitability remained elusive, so we closed down the business—but kept the learnings."
            dateRange="2015 - 2018"
            logo={aviateLogo}
            logoBackgroundColor="#4052a5" />
        </div>
      </Section>
    )
  }
}

export default WorkHistorySection
