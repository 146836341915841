import React from "react"

const SectionScrollIndicator = props =>
  <div class="section-scroll-indicator-wrapper">
    <div class="section-scroll-indicator-inner">
      <div class="section-scroll-indicator">
        <div class="section-scroll-indicator-line-box">
          <div class="section-scroll-indicator-line"></div>
        </div>
      </div>
    </div>
  </div>

export default SectionScrollIndicator
