import React from "react"

import Section from "../section"

// TODO resume pdf per graphql

// TODO get text, email, links etc and background photo per graphql

// TODO get per graphql
import githubLogo from "../../../content/assets/social-icons/github.svg"
// import fiverrLogo from "../../../content/assets/social-icons/fiverr.svg"
import facebookLogo from "../../../content/assets/social-icons/facebook.svg"
import linkedinLogo from "../../../content/assets/social-icons/linkedin.svg"
// import upworkLogo from "../../../content/assets/social-icons/upwork.svg"

import cvDocument from "../../../content/assets/documents/Christian_Konrad_CV.pdf"

const AboutMeText = props =>
  <div class="about-me-text">
    <p class="about-me-name">Hi! I'm Christian.</p>
    <p>I build <em>developer platforms</em> by leveraging 12 years of experience in product management, software engineering, and UI/UX design.</p>    
    <p>I describe myself as <em>T-shaped</em>: When designing and building new products, I blend technological expertise with a holistic understanding of markets, future trends, and user needs.</p>
    <p>I am driven by a passion for accelerating time-to-market, crafting seamless user experiences, and innovating solutions to complex challenges. <em>Let us think outside the box!</em></p>
    <p>You can find me near Frankfurt am Main, Germany—or let's connect now on <a href="https://www.linkedin.com/in/konradchristian">LinkedIn</a>.</p>
  </div>

const AboutMeInfoItem = props =>
  <div class="about-me-info-item">
    <div class="about-me-info-title">{props.title}</div>
    <div class="about-me-info-content">{props.children}</div>
  </div>

const AboutMeInfoArea = props =>
  <div class="about-me-info-area">
    {props.children}
  </div>

const SocialIcon = props =>
  <a class="social-icon" data-platform={props.platform} href={props.url} target="_blank"
    style={{backgroundImage: `url(${props.icon})`}}></a>

class AboutMeSection extends React.Component {
  render() {
    return (
      <Section name="aboutMe" title="About me" background="white" fitHeightToWindow={true} showScrollIndicator={true} paddingTop={12} onScrollIntoViewport={this.props.onScrollIntoViewport}>
				<AboutMeText />
				<AboutMeInfoArea>
          <AboutMeInfoItem title="Reach out by email">
            <a class="mail-link" href="&#x6d;&#x61;&#x69;&#x6c;&#x74;&#x6f;&#x3a;&#x6f;&#x66;&#x66;&#x69;&#x63;&#x65;&#x40;&#x63;&#x68;&#x72;&#x69;&#x73;&#x74;&#x69;&#x61;&#x6e;&#x2d;&#x6b;&#x6f;&#x6e;&#x72;&#x61;&#x64;&#x2e;&#x6d;&#x65;">&#x6f;&#x66;&#x66;&#x69;&#x63;&#x65;&#x40;&#x63;&#x68;&#x72;&#x69;&#x73;&#x74;&#x69;&#x61;&#x6e;&#x2d;&#x6b;&#x6f;&#x6e;&#x72;&#x61;&#x64;&#x2e;&#x6d;&#x65;</a>
          </AboutMeInfoItem>
          <AboutMeInfoItem title="Find me here">
            <SocialIcon platform="github" url="https://github.com/christian-konrad" icon={githubLogo} />
            <SocialIcon platform="linkedIn" url="https://www.linkedin.com/in/konradchristian" icon={linkedinLogo}/>
            {/* <SocialIcon platform="facebook" url="https://www.facebook.com/ckonrad.aviate" icon={facebookLogo}/> */}
            {/* <SocialIcon platform="upwork" url="https://www.upwork.com/freelancers/~01902261611942f7c7" icon={upworkLogo}/> */}
            {/* <SocialIcon platform="fiverr" url="https://www.fiverr.com/chris_konrad" icon={fiverrLogo}/> */}
          </AboutMeInfoItem>
          <AboutMeInfoItem title="Check out my resume">
            <a class="resume-link" href={cvDocument} target="_blank">Download resume</a>
          </AboutMeInfoItem>
				</AboutMeInfoArea>
      </Section>
    )
  }
}

export default AboutMeSection
