import React from "react"

import Section from "../section"
import ShowReel from "./showReel"

// TODO get data from graphql

// TODO rename asset folders to camelCase
// TODO images from graphql, too
import raftScreen1 from "../../../content/assets/show-reel/raft-1.png"
import raftScreen2 from "../../../content/assets/show-reel/raft-2.png"
import raftThesisScreen1 from "../../../content/assets/show-reel/raftThesisPage1.png"
import raftThesisScreen2 from "../../../content/assets/show-reel/raftThesisPage2.png"
import raftThesisScreen3 from "../../../content/assets/show-reel/raftThesisPage3.png"
import dataIntegrationPage1 from "../../../content/assets/show-reel/dataIntegrationPage1.png"
import dataIntegrationPage2 from "../../../content/assets/show-reel/dataIntegrationPage2.png"
import dataIntegrationPage3 from "../../../content/assets/show-reel/dataIntegrationPage3.png"

import essayDocument from "../../../content/assets/documents/SeminarGraphtechnologien_DataIntegrationWithTGG_Konrad_Xu.pdf"

class AcademicWorkSection extends React.Component {
  render() {
    return (
      <Section name="academicWork" title="Academic work" background="light" enableAOS={false} onScrollIntoViewport={this.props.onScrollIntoViewport}>
        <ShowReel items={[            
            {
              id: "modelBasedDataIntegrationInGraphDatabases",
              category: "Essay",
              date: "2020",
              title: "Model based approach of Data Integration in Graph Databases",
              description: "Essay about a model based approach for data integration in graph databases, explaining how to put the data integration on the model layer using triple graph grammars and executing it on the application layer using generated gremlin queries",
              url: essayDocument,
              /*
              actions: [
                { label: 'Show on GitHub', type: 'github', url: 'TODO' },
                { label: 'Play it', url: '' }
              ],
              */
              gallery: {
                type: "multi-app-screens",
                images: [
                  { src: dataIntegrationPage3, marginTop: "16px" },
                  { src: dataIntegrationPage2, marginTop: "8px" },
                  { src: dataIntegrationPage1 }
                ]
              }
            },
            {
              id: "raftLogReplication",
              category: "Distributed Systems Development",
              date: "2021",
              title: "ChronicleDB Cloud, a High-Performance Event Store",
              description: "Distributed event store / time series database with strong consistency using the Raft consensus protocol",              
              actions: [
                { label: 'Show on GitHub', type: 'github', url: 'https://github.com/christian-konrad/chronicledb-cloud' },
              ],
              gallery: {
                type: "multi-desktop-screens",
                images: [
                  { src: raftScreen2, marginTop: "8px" },
                  { src: raftScreen1 }
                ]
              }
            },
            {
              id: "raftLogReplicationThesis",
              category: "Research & Master's Thesis",
              date: "2021",
              title: "Replication in Data Stream Management Systems",
              description: "Thorough investigation of replication and partitioning mechanisms, consistency models, consensus protocols and analysis of the application of strong consistent replication to ChronicleDB using the Raft consensus protocol",              
              actions: [
                { label: 'Read thesis', url: 'https://github.com/christian-konrad/chronicle-db-on-raft-thesis/blob/c8abed9878d423339fc323b8d661ef4fec734bce/dist/replication-in-data-stream-management-systems.pdf' },
              ],
              gallery: {
                type: "multi-app-screens",
                images: [
                  { src: raftThesisScreen3, marginTop: "26px" },
                  { src: raftThesisScreen2, marginTop: "8px" },
                  { src: raftThesisScreen1 }
                ]
              }
            }
          ]} />
      </Section>
    )
  }
}

export default AcademicWorkSection
