import React from "react"

import AOS from 'aos'
import 'aos/dist/aos.css'

import Layout from "../components/frontPageLayout"
import SEO from "../components/seo"

import HeroSection from "../components/frontPage/heroSection"
import AboutMeSection from "../components/frontPage/aboutMeSection"
import MySkillsSection from "../components/frontPage/mySkillsSection"
import MyLearningsSection from "../components/frontPage/myLearningsSection"
import WorkHistorySection from "../components/frontPage/workHistorySection"
import ShowReelSection from "../components/frontPage/showReelSection"
import AcademicWorkSection from "../components/frontPage/academicWorkSection"
import HobbyProjectsSection from "../components/frontPage/hobbyProjectsSection"

import cardImage from "../../content/assets/og-card.png"

class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeSection: 'hero'
    }

    this.sectionRefs = {
      hero: React.createRef(),
      aboutMe: React.createRef(),
      mySkills: React.createRef(),
      myLearnings: React.createRef(),
      workHistory: React.createRef(),
      showReel: React.createRef(),
      academicWork: React.createRef(),
      hobbyProjects: React.createRef()
    }
  }

  componentDidMount() {
    AOS.init();
    this.scrollToComponent = require('react-scroll-to-component-ssr');
  }

  componentWillReceiveProps() {
    AOS.refresh();
  }

  goToFooter = () =>
    this.scrollToComponent(this.sectionRefs.hobbyProjects.current, {
      offset: 1000,
      align: 'top',
      duration: 1500
    })

  goToSection = section => {
    if (!this.sectionRefs[section]) return;
    if (this.state.activeSection === section) return;

    this.scrollToComponent(this.sectionRefs[section].current, {
      offset: 0,
      align: 'top',
      duration: 1500
    });
    this.setState({ activeSection: section });
  }

  handleSectionScrollIntoViewport = section => {
    if (!this.sectionRefs[section]) return;
    if (this.state.activeSection === section) return;

    this.setState({ activeSection: section });

    AOS.refresh();
  }

  render() {
    //const siteTitle = "Christian Konrad | Product Manager, Web Developer, UI/UX Designer"

    return (
      <Layout
        location={this.props.location}
        onNavItemClicked={this.goToSection}
        onCTAClicked={this.goToFooter}
        activeSection={this.state.activeSection}
        enableAOS={true}>
        <SEO
          title="Portfolio of Christian Konrad; a Product Manager, Software Engineer and UI/UX Designer, located near Frankfurt a. Main, Germany."
          keywords={[`Software Engineer`, `Web Developer`, `UI/UX Designer`, `Product Manager`, `Portfolio`, `User Habits`, `Product-Led Growth`]}
          image={cardImage}
          imageDimensions={{
            width: 1200,
            height: 630
          }}
        />
        <HeroSection ref={this.sectionRefs.hero} onCTAClicked={this.goToFooter} />
        <AboutMeSection ref={this.sectionRefs.aboutMe} onScrollIntoViewport={this.handleSectionScrollIntoViewport} />
        <MySkillsSection ref={this.sectionRefs.mySkills} onScrollIntoViewport={this.handleSectionScrollIntoViewport} />
        <MyLearningsSection ref={this.sectionRefs.myLearnings} onScrollIntoViewport={this.handleSectionScrollIntoViewport} />
        <WorkHistorySection ref={this.sectionRefs.workHistory} onScrollIntoViewport={this.handleSectionScrollIntoViewport} />
        <ShowReelSection ref={this.sectionRefs.showReel} onScrollIntoViewport={this.handleSectionScrollIntoViewport} />
        <AcademicWorkSection ref={this.sectionRefs.academicWork} onScrollIntoViewport={this.handleSectionScrollIntoViewport} />
        <HobbyProjectsSection ref={this.sectionRefs.hobbyProjects} onScrollIntoViewport={this.handleSectionScrollIntoViewport} />
      </Layout>
    )
  }
}

export default IndexPage
