import React from "react"

import Header from "./header/header"

const FrontPageHeader = props =>
  <Header isFullWidthHeader={false} 
    onNavItemClicked={props.onNavItemClicked}
    onCTAClicked={props.onCTAClicked}
    activeSection={props.activeSection}
    items={[
      { section: "aboutMe", title: "About me" },
      { section: "mySkills", title: "My skills" },
      { section: "workHistory", title: "Work history" },
      { section: "showReel", title: "Show reel" },
      { page: "/books", title: "Book notes" },
      { page: "/blog", title: "Blog" }
  ]}></Header>

export default FrontPageHeader
