import React from "react"

import Section from "../section"
import SectionContent from "../sectionContent"

// TODO get skill data per graphql

// TODO show  skills as graph, sub skills ?
// TODO correct skill markup, use graph annotations

const showSkillOverview = props => {
  // noop
  // TODO show modal with details about the skill
};

const SkillTag = props =>
  <div class="skill-tag" data-aos="zoom-in" onClick={() => showSkillOverview(props)}>{props.children}</div>

const SkillTags = props =>
  <div class="skill-tags" style={{
      maxWidth: props.maxWidth ? props.maxWidth : 'none'
    }}>
    {props.skills.map((item, index) => <SkillTag key={index}>{item}</SkillTag>)}
    {props.children}
  </div>

class MySkillsSection extends React.Component {
  render() {
    return (
      <Section name="mySkills" title="My skills" background="dark" customContent={true} showScrollIndicator={true} onScrollIntoViewport={this.props.onScrollIntoViewport}>
        <SectionContent title="My skills">
          <p class="section-additional-info">My skills are backed up by 12+ years of experience in building developer-orientered products <br/> and 3 years of building a full stack SaaS application as a startup founder.</p>
          
          <h3 class="subsection-header" data-aos="fade-right">Product management</h3>
          <SkillTags skills={[
            'Lean/Agile',
            'SaaS Applications',
            'Product Discovery',
            'Customer Development',
            'Jobs-To-Be-Done',
            'Continuous Delivery',
            'Customer Interviews',
            'Data Analytics',
            'API Management',
            'Design Thinking',
            'Rapid Prototyping',
            'Three Horizons Model',
            'OKRs and Goal Management',
            'UI/UX Design',
            'Accelerate Go-To-Market',
            'Conference Speaking',
            'Developer Relations',
            'Technical Writing'
          ]} />

          <h3 class="subsection-header" data-aos="fade-right">Software engineering & system design</h3>
          <SkillTags skills={[
            'API Design & Development',
            'Full Stack Development',
            'Distributed Systems',
            'Time Series Databases / Event Stores',
            'Machine Learning',
            'Information Retrieval',
            'Semantic Web',
            'Voice Interfaces / Chatbots'
          ]} />

          <h3 class="subsection-header" data-aos="fade-right">Domain expertise</h3>
          <SkillTags skills={[
            'API Orchestration',
            'Business Process Management and Notation (BPMN)',
            'Developer Ecosystems',
            'Human Task Orchestration',
            'Customer Relationship Management (CRM)',
            'Case Management',
            'Software Development Lifecycle (SDLC)',
            'Low Code Application Platforms',
            'iPaaS',
            'Workforce Intelligence',
            'Process Orchestration',
            'Data Visualization',
            'Legacy Application Transformation & Feature Parity'
          ]} />
        </SectionContent>
        <SectionContent title="My programming toolbox">
          <SkillTags maxWidth="900px" skills={[
		        'JavaScript',
		        'ECMA 6',            
            'React',
            'Java',
            'OpenAPI/Swagger',
            'Spring Boot',            
            'Node (Express)',            
            'gRPC',
            'Kafka',
            'ElasticSearch',
            'MongoDB',
            'Python',
            'Docker/Kubernetes',
            'Preact',
            'Jupyter',
            'scikit-learn',
            'Gatsby',
            'Dart',
            'Ruby on Rails',
            'SQL',
            'Netlify',
            'Git',
            'GitHub/GitLab',
            'AWS',                      
            'Azure',            
            'Groovy',
            'Hadoop',
            'C/C++',
            'JQuery',
            'Jenkins',
            'GitHub Actions',
            'Babel',
            'Webpack',
            'OpenAI',
            'Maven/Gradle'
          ]} />
        </SectionContent>
      </Section>
    )
  }
}

export default MySkillsSection
