import React from "react"

import Typewriter from 'typewriter-effect'

import SectionScrollIndicator from "../sectionScrollIndicator"

// TODO get per graphql
// import fiverrLogo from "../../../content/assets/social-icons/full/fiverr.svg"
import linkedInLogo from "../../../content/assets/social-icons/full/linkedin.svg"
import githubLogo from "../../../content/assets/social-icons/full/github.png"
// import upworkLogo from "../../../content/assets/social-icons/full/upwork.svg"

const CodeBoxRow = props =>
<div class="code-box-row" data-line-number={props.number}>{props.children}</div>

const CodeBoxRows = props =>
  <div class="code-box-rows">
    {props.lines.map((line, index) =>
      <CodeBoxRow key={index + 1} number={index + 1}>{line}</CodeBoxRow>
    )}
  </div>

const CodeBox = props =>
  <div class="code-box">
    <div class="code-box-tabs">
      <div class="code-box-tab" data-active="true">app.js</div>
      <div class="code-box-tab" data-active="false">index.html</div>
      <div class="code-box-buttons">
        <div class="code-box-button"></div>
        <div class="code-box-button"></div>
        <div class="code-box-button"></div>
      </div>
    </div>
    <pre>
      <code>
        <CodeBoxRows lines={[
          <span><i>function</i> <em>buildOutstandingProducts(</em>users<em>)</em> &#123;</span>,
          <span>  <em>focusOnUsers(</em>users<em>)</em></span>,
          <span>     .<i>then</i>(() => <em class="be-innovative-token">beAgile()</em>)</span>,
          <span>     .<i>then</i>(() => <em>maximizeUserExperience()</em>)</span>,
          <span>     .<i>then</i>(() => <em>laserFocus()</em>)</span>,
          <span>     .<i>then</i>(() => <em>deliverContinuously()</em>);</span>,
          <span>&#125;</span>
        ]} />
      </code>
    </pre>
  </div>

const SocialLink = props =>
  <a class="social-link" data-platform={props.platform} href={props.url} target="_blank" style={{
      backgroundImage: `url(${props.icon})`,
      height: props.height ? props.height : '20px',
      width: props.width ? props.width : '70px',
      marginTop: props.marginTop ? props.marginTop : 0
    }}></a>

const HeroCTAButton = props =>
  <button class="call-to-action" onClick={props.onClick}>Contact me</button>

class HeroSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      opacity: 1,
      padding: {
        top: 120,
        bottom: 280
      }
    };

    this.ref = React.createRef();
  }

  _fitHeightToWindow() {
    const originalHeight = this.ref.current.clientHeight;
    const windowHeight = window.innerHeight;
    const originalPadding = {
      top: 120,
      bottom: 280
    };

    const additionalPaddings = (windowHeight - originalHeight) / 2;

    this.setState({
      padding: {
        top: originalPadding.top + additionalPaddings,
        bottom: originalPadding.bottom + additionalPaddings
      }
    });
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);

    this._fitHeightToWindow();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  handleScroll = event => {
    const scrollTop = event.srcElement.documentElement.scrollTop;
    this.setState({ opacity: Math.min(1, 300 / scrollTop) });
  }

  handleResize = event => {
    this._fitHeightToWindow();
  }

  render() {
    return (
      <section class="page-section" data-section="cta"
        ref={this.ref}
        style={{
          paddingTop: this.state.padding.top,
          paddingBottom: this.state.padding.bottom
        }}>
				<div class="section-content" style={{ opacity: this.state.opacity }}>
		      <div class="page-subtitle">Hi! I am a</div>
          <h1 class="page-title typewriter-title">
            <Typewriter options={{
                strings: ["Product Manager", "Software Engineer", "UI/UX Designer"],
                autoStart: true,
                loop: true
            }} />
          </h1>
		      <div class="cta-wrapper">
		        <div class="cta-column">
		          <HeroCTAButton onClick={this.props.onCTAClicked} />
							<div class="social-links-wrapper">
								<div class="social-links-label">Or find me on</div>
			          <div class="social-links">
                  {/* <SocialLink platform="fiverr" url="https://www.fiverr.com/chris_konrad" icon={fiverrLogo} height="16px" width="62px" marginTop="1px" /> */}
                  {/* <SocialLink platform="upwork" url="https://www.upwork.com/freelancers/~01902261611942f7c7" icon={upworkLogo} marginTop="2px" /> */}
                  <SocialLink platform="github" url="https://github.com/christian-konrad" icon={githubLogo} />
                  <SocialLink platform="linkedIn" url="https://www.linkedin.com/in/konradchristian" icon={linkedInLogo} style={{marginTop: 1}} />
			          </div>
							</div>
		        </div>
		        <div class="cta-column" data-column-id="code-box">
		          <CodeBox />
		        </div>
					</div>
	      </div>
        <SectionScrollIndicator />
	    </section>
    )
  }
}

export default HeroSection
