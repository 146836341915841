import React from "react"

import Section from "../section"
import ShowReel from "./showReel"

// TODO get data from graphql

// TODO rename asset folders to camelCase
// TODO images from graphql, too
import themeBasedRoadmap from "../../../content/assets/show-reel/theme-based-roadmap-trello.png"
import streetFighterDesktop from "../../../content/assets/show-reel/street_fighter.png"
import pandocBoilerplate from "../../../content/assets/show-reel/pandoc-boilerplate.png"
import dataIntegrationPage1 from "../../../content/assets/show-reel/dataIntegrationPage1.png"

class HobbyProjectsSection extends React.Component {
  render() {
    return (
      <Section name="hobbyProjects" title="Hobby projects" background="dark" enableAOS={false} onScrollIntoViewport={this.props.onScrollIntoViewport}>
        <ShowReel items={[
            {
              id: "themeBasedRoadmap",
              category: "Product Management/Planning Framework/Microservice",
              date: "2021",
              title: "Theme-Based Roadmap using Trello as the Backend",
              description: "Simple theme-based roadmap using Trello as the backend. Manage your roadmap content using the simple, well-known interface of Trello boards while having a well-formatted, readonly roadmap",
              actions: [
                { label: 'Show on GitHub', type: 'github', url: 'https://github.com/christian-konrad/theme-based-roadmap-using-trello' },
                // { label: 'Play it', url: 'https://christian-konrad.github.io/no-canvas-street-fighter-clone' }
              ],
              gallery: {
                images: [{ src: themeBasedRoadmap }]
              }
            },
            {
              id: "streetFighterJSClone",
              category: "Frontend/Game Engine",
              date: "2018",
              title: "No Canvas Street Fighter Clone",
              description: "Street Fighter clone prototype, written entirely in plain JS and CSS without the use of canvas or frameworks",
              url: "https://www.cursor.de/software/cursor-crm",
              actions: [
                { label: 'Show on GitHub', type: 'github', url: 'https://github.com/christian-konrad/no-canvas-street-fighter-clone' },
                { label: 'Play it', url: 'https://christian-konrad.github.io/no-canvas-street-fighter-clone' }
              ],
              gallery: {
                images: [{ src: streetFighterDesktop }]
              }
            },
            {
              id: "scientificPaperBoilerplate",
              category: "Scientific paper boilerplate",
              date: "2020",
              title: "Markdown/LaTeX boilerplate for scientific papers",
              description: "Write your well formatted professional paper, essay or thesis at ease with markdown and latex and export it as PDF (with bibliography).",
              actions: [
                { label: 'Show on GitHub', type: 'github', url: 'https://github.com/christian-konrad/markdown-latex-paper-boilerplate' },
              ],
              gallery: {
                images: [{ src: pandocBoilerplate }]
              }
            }
          ]} />
      </Section>
    )
  }
}

export default HobbyProjectsSection
