import React from "react"

import Section from "../section"

// TODO get per graphql
import reactIcon from "../../../content/assets/skill-icons/react.svg"
// import pwaIcon from "../../../content/assets/skill-icons/pwa.svg"
import machineLearningIcon from "../../../content/assets/skill-icons/machine-learning.svg"
import distributedSystemsIcon from "../../../content/assets/skill-icons/distributed-systems.svg"
// import languageIcon from "../../../content/assets/skill-icons/language.svg"
import llmIcon from "../../../content/assets/skill-icons/openai.png"
import pineconeIcon from "../../../content/assets/skill-icons/pinecone.png"
import blockchainIcon from "../../../content/assets/skill-icons/blockchain.svg"
import web3jsIcon from "../../../content/assets/skill-icons/web3js.svg"
import neo4jIcon from "../../../content/assets/skill-icons/neo4j.png"
// import graphTransformIcon from "../../../content/assets/skill-icons/graph-transform.svg"

// TODO get skill data per graphql

// TODO correct skill markup, use graph annotations

const SkillProgressTag = props =>
  <div class="skill-progress-tag" data-progress={props.progress} data-aos="zoom-in">
    <div class="skill-icon" style={{
      backgroundImage: `url(${props.icon})`,
      backgroundSize: props.iconSize ? props.iconSize : 'contain'
    }}></div>
    <div class="skill-name">{props.children}</div>
  </div>

const SkillProgressTags = props =>
  <div class="skill-progress-tags">
    {props.skills.map((item, index) =>
      <SkillProgressTag key={index} progress={item.progress} icon={item.icon} iconSize={item.iconSize}>{item.name}</SkillProgressTag>
    )}
    {props.children}
  </div>

class MyLearningsSection extends React.Component {
  render() {
    return (
      <Section name="myLearnings" title="What I'm currently learning" background="light" showScrollIndicator={true} onScrollIntoViewport={this.props.onScrollIntoViewport}>
        <SkillProgressTags skills={[
          { name: 'LLMs + GenAI', progress: 30, icon: llmIcon },
          { name: 'React/Gatsby', progress: 70, icon: reactIcon, iconSize: '140%' },
          { name: 'Vector Databases', progress: 10, icon: pineconeIcon },
          { name: 'Machine Learning', progress: 80, icon: machineLearningIcon },
          { name: 'Distributed Systems', progress: 80, icon: distributedSystemsIcon },      
          { name: 'Graph Databases', progress: 40, icon: neo4jIcon },
          { name: 'Blockchain + Smart Contracts', progress: 40, icon: blockchainIcon },
          { name: 'Web3 / dApps', progress: 10, icon: web3jsIcon, iconSize: '200%' },          
        ]} />
      </Section>
    )
  }
}

export default MyLearningsSection
