import React from "react"

const SectionContentTitle = props =>
  props.title
    ? <h2 class="section-title">{props.title}</h2>
    : null

class SectionContent extends React.Component {
  render() {
    const { title, enableAOS, children } = this.props

    return (
      <div class="section-content" data-aos={enableAOS === false ? '' : 'fade-right'}>
        <SectionContentTitle title={title} />
        {children}
      </div>
    )
  }
}

export default SectionContent
