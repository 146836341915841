import React from "react"

import SectionContent from "./sectionContent"
import SectionScrollIndicator from "./sectionScrollIndicator"

class Section extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      padding: {
        top: 84,
        bottom: 84
      }
    };

    if (this.props.paddingTop != null)
      this.state.padding.top = this.props.paddingTop;

    if (this.props.paddingBottom != null)
      this.state.padding.bottom = this.props.paddingBottom;

    this.ref = React.createRef();
  }

  _fitHeightToWindow() {
    const originalHeight = this.ref.current.clientHeight;
    const windowHeight = window.innerHeight;
    const originalPadding = this.state.padding;

    if (this.props.paddingTop != null)
      originalPadding.top = this.props.paddingTop;

    if (this.props.paddingBottom != null)
      originalPadding.bottom = this.props.paddingBottom;

    const additionalPaddings = (windowHeight - originalHeight) / 2;

    this.setState({
      padding: {
        top: originalPadding.top + additionalPaddings,
        bottom: originalPadding.bottom + additionalPaddings
      }
    });
  }

  componentDidMount() {
    if (this.props.fitHeightToWindow) {
      window.addEventListener('resize', this.handleResize);
      this._fitHeightToWindow();
    }
    window.addEventListener('scroll', this.handleScroll);
    //window.addEventListener('wheel', this.handleWheel);
  }

  componentWillUnmount() {
    if (this.props.fitHeightToWindow)
      window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
    //window.removeEventListener('wheel', this.handleWheel);
  }

  handleScroll = event => {
    const scrollTop = event.srcElement.documentElement.scrollTop;

    // TODO depending on scroll direction
    if (this.ref.current.getBoundingClientRect().top < 600 &&
      this.ref.current.getBoundingClientRect().top > 600 - this.ref.current.clientHeight) {
      if (typeof this.props.onScrollIntoViewport === 'function')
        this.props.onScrollIntoViewport(this.props.name);
    }
  }

  /*
  handleWheel = event => {
    const scrollTop = event.srcElement.documentElement.scrollTop;

    console.log("wheeled")
  }
  */

  handleResize = event => {
    this._fitHeightToWindow();
  }

  render() {
    const { name, title, background, customContent, enableAOS, showScrollIndicator, children } = this.props

    return (
      <section class="page-section" data-section={name} data-background={background}
        ref={this.ref}
        style={{
          paddingTop: this.state.padding.top,
          paddingBottom: this.state.padding.bottom
        }}>
        <div class="section-background"></div>
        {customContent ? children : <SectionContent title={title} enableAOS={enableAOS}>{children}</SectionContent>}
        {showScrollIndicator && <SectionScrollIndicator />}
	    </section>
    )
  }
}

export default Section
